.callbacks {
    &__row {
        display: flex;

        justify-content: flex-start;

        padding-left: 20px;
        padding-top: 10px;

        height: 70px;
        border: 1px solid lightgray;
        margin-bottom: 5px;
    }

    &__col {
        width: 300px;

        h4 {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.2;
            color: #A0AEC0;
        }

        svg {
            cursor: pointer;
        }
    }

    &__loading-row {
        display: flex;
        justify-content: center;
    }
}