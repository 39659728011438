.create-project {
  // ----- Step 1 Styles
  & .select-region {
    margin-bottom: 70px;

    &-form {
      padding: 0 !important;
    }

    &-map {
      height: 345px;
      margin-bottom: 40px;
    }

    & .ant-btn-primary {
      height: fit-content !important;
      padding: 12px 30px;
      border-radius: 10px;
      background-color: #128FFF !important;

      & span {
        color: white;
        font-size: 20px;
        font-weight: 600;
      }
    }

    & .form-btns {
      & button {
        height: auto;
        color: #fff;
        font-weight: 600;
        padding: 12px 30px !important;
        background: #128FFF;
        border-radius: 10px;
      }
  
      & .success-button {
        background: #18CEC9;
        border: none;
      }
    }
  }

  & .plotSearch {
    &-filter {
      margin-bottom: 30px;

      & .ant-form-item {
        margin-bottom: 0;
      }

      & .ant-form-item-control-input {
        width: 125px;
      }

      & .filter {
        &-label {
          font-weight: 400;
          font-size: 16px;
          color: #A0AEC0;
          margin-bottom: 0;
        }
        &-btn {
          padding: 10px 15px;
        }
      }
    }
    &-items {
      display: grid;
      grid-gap: 32px;
      grid-template-columns: repeat(3, 1fr);
    }

    &-preview {
      height: 220px;
      background-color: #A0AEC0;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    &-content {
      padding: 13px 20px 12px;
      border: 0.5px solid #A0AEC0;
    }
    &-subtitle {
      font-weight: 500;
      font-size: 16px;
      color: #128FFF;
      margin-bottom: 8px;
    }
    &-title {
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      margin-bottom: 8px;
    }
    &-list {
      padding-left: 10px;
      margin-bottom: 0;
      &-li {
        font-weight: 400;
        font-size: 14px;
        color: #96A0B5;
      }
    }
    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 7px 10px;
      padding-left: 23px;
      border: 0.5px solid #A0AEC0;
      border-top: none;
    }
    &-price {
      font-weight: 400;
      font-size: 16px;
      color: #96A0B5;
      margin-bottom: 0;
    }
  }
}