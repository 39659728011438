.aboutSystem {
    background: #EDF2F7;
  
    & .header-landing-inner {
      position: static;
    }
    
    &-block {
      padding: 20px 0 140px;
      position: relative;
    }

    &-investproject {
        margin-top: 90px;
    }

    &-header {
      margin-bottom: 50px;
    }
  
    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;
  
      & > div {
        display: flex;
        flex-direction: column;
        grid-gap: 30px;
      }
  
      &-item {
        padding-left: 26px;
        position: relative;
  
        &::before {
          content: '';
          width: 12px;
          height: 12px;
  
          position: absolute;
          left: 0;
          top: 5px;
  
          border: 3px solid #28C397;
          border-radius: 50%;
        }
  
        & p,
        & a {
          font-weight: 400;
          font-size: 16px;
          line-height: 1.6;
          color: #000000;
          margin-bottom: 0;
        }
  
        & a {
          color: #128FFF;
          text-decoration: underline;
        }
      }
    }

    &__h2 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        // line-height: 100px;

        /* or 500% */
        display: flex;
        align-items: center;
        text-transform: uppercase;

        color: #080A1C;
    }
    
    &__first {
        // height: 140px;
        width: 593px;
    
        & p {
            font-family: 'Montserrat';
            font-style: normal;
            // font-weight: 600;
            font-size: 16px;
            line-height: 20px;

            /* or 125% */
            letter-spacing: -0.06em;

            color: #080A1C;
        }

        &__bold {
            font-weight: 600;
        }
    
        & .aboutSystem__first__p {
            margin-bottom: 25px;
        }

        & button {
            height: 40px !important;
            width: 177px;

            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            color: #FFFFFF;
            
            background: #128FFF;

            border-radius: 10px;
        }
    }

    &__second {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        margin-top: 50px;

        & .aboutSystem__h2 {
            margin-bottom: 40px;
        }

        &__grid {
            display: grid;
            grid-template-columns: 396px 396px 396px;
            grid-template-rows: 44px 44px 44px 44px 44px;
            row-gap: 21px;
            column-gap: 46px;

            & .grid__item {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &__svg-holder {
                    position: relative;

                    width: 44px;
                    height: 44px;

                    display: flex;
                    
                    justify-content: center;
                    align-items: center;

                    margin-right: 15px;
                    border-radius: 10px;

                    &__background {
                        background: rgba(18, 143, 255, 0.1);
                        filter: blur(5px);
                        border-radius: 10px;
                        
                        position: absolute;
                        left: 0;
                        top: 0;

                        width: 44px;
                        height: 44px;
                    }
                }

                & span {  
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;

                    /* or 250% */
                    display: flex;
                    align-items: center;

                    max-width: 337px;

                    color: #121C2B;
                }
            }
        }
    }

    &__third {
        position: relative;

        margin-top: 50px;

        &__blue-box {
            position: absolute;

            height: 39px;
            width: 5px;

            background: #128FFF;
        }

        &__text-holder {
            margin-left: 32px;

            height: 39px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            & p {
                margin: 0;
                padding: 0;
                
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;

                /* or 125% */
                letter-spacing: -0.06em;

                color: #080A1C;
            }
        }
    }

    &__investproject__second {

        margin-top: 50px;

        & p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
            color: #121C2B;
        }
    }

    &__investproject__steps {

    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

        & .step {
            display: flex;
            align-items: center;

            margin-right: 57px;

            & svg {
                margin-right: 14px;
            }

            & span {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                /* identical to box height, or 143% */

                /* 121C2B */
                color: #121C2B;
            }
        }

        &__text {
            margin-top: 30px;

            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            /* identical to box height, or 143% */

            /* 121C2B */
            color: #121C2B;
        }

        &__images {
            display: flex;

            margin-top: 47px;
            margin-left: -10px;

            & img {
                margin-right: 35px;
            }
        }
    }
}

.aboutSystem__picture-first {
    position: absolute;
    right: 300px;
    top: 39px;
}

.aboutSystem__picture-second {
    position: absolute;
    right: 300px;
}

@media screen and (max-width: 1919px) {
    .aboutSystem__picture-first {
        position: absolute;
        right: 100px;
        top: 39px;
    }

    .aboutSystem__picture-second {
        position: absolute;
        right: 100px;
    }
}

@media screen and (max-width: 1359px) {
    .aboutSystem__picture-first {
        position: static;
        margin-bottom: 20px;
    }

    .aboutSystem__picture-second {
        position: static;
        margin-bottom: 20px;
    }

    .aboutSystem__investproject__steps__images {
        & img {
            width: 33vw;
        }
    } 

    .aboutSystem__second__grid {
        display: grid;
        grid-template-columns: 396px 396px;
        grid-template-rows: 44px;
        row-gap: 21px;
        column-gap: 46px;
    }
}