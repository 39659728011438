.projectModel {
  & .header-landing{
    margin-bottom: 40px;
    &-inner {
      position: static !important;
    }
  }

  & .step-title {
    font-weight: 400;
    font-size: 16px;
    color: #2D3748;
    margin-bottom: 20px;
  }
  & .seasonsCheckbox {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px 50px;
    margin-bottom: 50px;

    &-item {
      display: flex;
      align-items: center;
    }

    &-content {
      min-width: 160px;

      display: flex;
      grid-gap: 15px;
      align-items: center;

      padding: 15px;
      border: 0.5px solid #A0AEC0;
    }

    &-block {
      width: 40px;
      height: 40px;
    }

    &-label {
      font-weight: 400;
      font-size: 20px;
      color: #000000;
    }
  }

  & .percentages {
    max-width: 960px;
    margin-bottom: 50px;

    & h3 {
      margin-bottom: 0px !important;
    }

    &-input {
      border: none !important;
      box-shadow: none !important;
      & .ant-input-number-input {
        height: 50px;

        font-weight: 500;
        font-size: 20px;
        color: #2D3748;

        padding: 15px 25px;

        background: rgba(237, 242, 247, 0.8);
        border-radius: 10px;
      }

      & .ant-input-number-handler-wrap {
        background: rgba(237, 242, 247, 0.8);
      }
    }

    &-title {
      font-weight: 500;
      font-size: 20px;
      color: #2D3748;
      margin-bottom: 0 !important;
    }
  }

  & .calculator {
    &-form,
    &-total {
      margin-bottom: 50px;
      & h4,
      & .ant-form-item {
        margin-bottom: 0px !important;
      }
    }

    &-form {
      & .my-ant-input {
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;
        & .ant-input-number-input {
          height: 50px;

          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          
          padding: 15px 20px;
  
          background: rgba(237, 242, 247, 0.8);
          border-radius: 10px;
        }
  
        & .ant-input-number-handler-wrap {
          display: none !important;
        }
      }

      & h4 {
        font-weight: 400;
        font-size: 16px;
        color: #2D3748;
      }
    }

    &-total {
      margin-bottom: 25px;
      max-width: 960px;

      padding: 0 20px;
      background: #128FFF;
      border-radius: 10px;

      &-item {
        padding: 18px 0 22px;
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);

        & .title {
          font-weight: 400;
          font-size: 20px;
          color: rgba(255, 255, 255, 0.8);
        }

        & .price {
          font-weight: 700;
          font-size: 20px;
          color: #FFFFFF;
        }
      }

      &-item:last-of-type {
        border-bottom: none !important;
      }
    }

    &-description {
      margin-bottom: 50px;
      max-width: 960px;
      font-weight: 400;
      font-size: 16px;
      color: #2D3748;
    }
  }

  & .supportMeasures {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 35px;

    &-title {
      font-weight: 500;
      font-size: 20px;
    }

    &-item {
      height: 140px;
      padding: 15px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(#E2E8F066, 0.4);
    }

    &-item:nth-child(3n+2){
      background: rgba(226, 232, 240, 0.8);
    }

    &-item:nth-last-child(-n+2),
    &-item:nth-last-child(-n+4) {
      grid-column: 1/3;
    }

    &-item:nth-last-child(3),
    &-item:nth-last-child(1) {
      grid-column: 3/5;
    }

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      text-align: center;
      color: #000000;
    }
  }

  & .regForm {
    padding: 0 !important;
  }
}