h4 {
  text-align: left;
}

.defaultBg {
  border: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
}

.registratinTabs {
  width: 100%;
  text-align: left;
  height: 67px;
  display: flex;
  align-items: center;
  margin-bottom: 13px;

  span {
    padding: 16px 40px;
    color: #A0A0A0;
    font-size: 20px;
    // font-family: SFProDisplay-Medium, sans-serif;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }

  span.activeRegTab {
    color: #3C4E77;
    // font-family: SFProDisplay-Bold, sans-serif;
    font-weight: bold;
    border-bottom: 3px solid #338DE0;
  }
}

main > div {
  padding: 30px 0px !important;
}

.regForm {
  padding: 34px 40px 106px 40px;
  .ant-input-lg {
    padding: 10px 11px;
    border-radius: 8px;
  }
  .ant-input {
    border: none !important;
    background: rgba(237, 242, 247, 0.8) !important;
  }
  .ant-input-affix-wrapper {
    padding: 10px 11px;
    border-radius: 8px;
    background: rgba(237, 242, 247, 0.8) !important;
    .ant-input-lg {
      border-radius: 0;
    }
  }
}

.dangerText {
  color: #FF7B7B;
}

.errorBin {
  display: flex;
  align-items: center;
  justify-content: left;
}

.successText {
  color: #38B836;
}

.primaryText {
  color: #338DE0;
}

.mediumFont {
  // font-family: SFProDisplay-Medium, sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.dflex {
  display: flex;
  align-items: center;
}

.profilePage {
  form{
    padding-left: 8px;
  }
  h3 {
    // font-family: SFProDisplay-Regular, sans-serif;
    font-weight: normal;
    color: #A0A0A0;
    font-size: 18px;
  }

  .ant-form-item-control-input {
    font-size: 18px;
  }
}
main.ant-layout-content {
  margin: 0px !important;
  padding: 0 30px!important;
  position: static !important;
  // background: #EDF2F7 !important;
}