.footer {
  padding: 50px 0 20px;
  background: #080A1C;

  &-inner {
    display: grid;
    grid-template-columns: .8fr 1fr;
    grid-gap: 20px;
    margin-bottom: 55px;
  }

  &-left {
    max-width: 270px;
  }

  &-logo {
    display: block;
    height: 60px;
    width: auto;
    margin-bottom: 35px;
  }

  &-socialNetworks {
    display: flex;
    grid-gap: 10px;
    margin-bottom: 50px;

    &-link {
      height: 38px;
      width: 38px;
    
      display: flex;
      align-items: center;
      justify-content: center;
    
      background: rgba(18, 143, 255, 0.1);
      border-radius: 5px;
      transition: all .2s ease-in-out;
    
      & svg {
        fill: white;
        opacity: 0.5;
        transition: all .2s ease-in-out;
      }

      &:hover {
        background: rgba(18, 143, 255, 0.5);
        & svg {
          opacity: 1;
        }
      }
    }
  }

  &-submit {
    height: auto !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    padding: 15px 30px;
    border-radius: 10px;
    background: #128FFF;
  }

  &-right {
    display: flex;
    grid-gap: 75px;
  }

  &-col-title {
    font-weight: 400;
    font-size: 17px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 38px;
  }

  &-nav {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  &-link-block {
    display: flex;
    align-items: center;
    grid-gap: 10px;

    margin-bottom: 18px;

    & p {
      margin-bottom: 0;
    }

    &:hover p{
      color: #128FFF !important;
      &::after {
        width: 100%;
      }
    }
  }

  &-link-block p,
  &-link {
    width: fit-content;
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: #fff;
    white-space: nowrap;
    position: relative;
    transition: color .3s ease-in-out;

    &::after {
      content: '';

      display: block;
      width: 0;
      height: 2px;

      position: absolute;
      left: 0;
      top: calc(100% + 4px);

      background-color: #128FFF;
      transition: width .3s ease-in-out;
    }
  }

  & p.description {
    max-width: 430px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
    color: #fff;
    margin-bottom: 50px;
  }

  &-copyright {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.2;
    color: #fff;
    text-align: end;
  }
}