.fotgetBtn {
  font-size: 20px;
  text-decoration: underline;
  float: left;
  margin-left: 30px;
  color: #1890ff;

  &:hover {
    cursor: pointer;
  }
}
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus {
  box-shadow: none;
}
.ant-form-item-explain div {
  text-align: left;
  padding-left: 11px;
}