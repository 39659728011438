.projectFinished-landing {
  background: #EDF2F7;

  & .header-landing {
    margin-bottom: 20px;
    &-inner {
      position: static;
    }
  }

  & .landing-title {
    margin-bottom: 50px;
  }

  &-block {
    min-height: 80vh;
    margin-bottom: 140px;
  }

  &-inner {
    display: grid;
    grid-template-columns: 1fr 310px;
    grid-gap: 32px;
  }

  &-item {
    display: grid;
    grid-template-columns: 57px 1fr;
    grid-gap: 10px;
  }
  &-rows {
    margin-bottom: 10px;
  }
  &-row {
    display: grid;
    grid-template-columns: 200px 1fr;
    background: #E3EEF6;
    margin-bottom: 10px;

    & > div {
      padding: 20px 20px 18px;
      font-size: 16px;
      line-height: 1.1;
      color: #080A1C;
    }

    & .title {
      background: #C8DCEE;
    }

    & .value {
      font-weight: 600;
    }
  }

  &-preview {
    width: 516px;
    height: 310px;
  }
}