.header-landing {  
  position: relative;
  & .nav-links {
    display: flex;
    align-items: center;
    grid-gap: 30px;
  }

  &-top {
    height: 67px;
    padding: 10px 0;
    background-color: #fff;

    & svg{
      height: 100%;
      width: 55px;
    }

    & .container {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 30px;
    }

    & .nav-toggle {
      height: 30px;
      width: 30px;
      padding: 10px 0;
      font-size: 0;
      color: transparent;
      border: 0;
      background: none;
      cursor: pointer;
      position: relative;

      &.active {
        & span::after,
        & span::before {
          width: 100%;
        }
        & span::after {
          transform-origin: left bottom;
          transform: rotate(-45deg) translate3d(0,3px,0);
        }
        & span::before {
          transform-origin: left top;
          transform: rotate(45deg) translate3d(0,-2px,0);
        }

        & span {
          background: none;
        }
      }

      &-item {
        width: 100%;
        height: 3px;
        background-color: #67a1d6;
        border: 10px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        transition: background-color .2s linear;

        &::after,
        &::before {
          content: "";
          width: 100%;
          height: 3px;
          background-color: #67a1d6;
          border: 10px;
          position: absolute;
          left: 0;
          z-index: 1;
          transition: transform .2s linear;
        }

        &::before {
          top: -8px;
          width: 45%;
        }

        &::after {
          width: 65%;
          bottom: -8px;
        }
      }
    }

    & .main-header-nav {
      display: grid;
      grid-template-columns: 35% 1fr;
      width: 100%;
      height: calc(100vh - 100%);
      position: absolute;
      top: 100%;
      z-index: 100;

      opacity: 0;
      visibility: hidden;
      transition: opacity .3s linear;

      &.active {
        opacity: 1;
        visibility: visible;
      }

      &::after,
      &::before {
        content: '';
        display: block;

        position: absolute;
        z-index: 2;

        border: 38px solid rgba(#fff, .1);
        border-radius: 50%;
      }

      &::after {
        height: 300px;
        width: 300px;

        top: -45px;
        right: 230px;
      }

      &::before {
        height: 530px;
        width: 530px;

        top: 280px;
        left: 260px;
        border-width: 100px;
      }

      &-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 97px 0;
        background-color: #67a1d6;
        position: relative;

        &-btns {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          grid-gap: 35px;
        }
        & .logo {
          cursor: pointer;
          display: block;
          height: 69px;
          position: relative;
          z-index: 10;
          -webkit-animation: to-top 1.2s forwards;
          animation: to-top 1.2s forwards;

          & svg {
            height: 69px;
            width: 92px;
          }
        }
      }
      &-btn {
        cursor: pointer;
        position: relative;
        z-index: 3;
        font-size: 48px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
        color: #a0c9ef;
        padding: 10px 69px 10px 10px;
        border: none;
        border-right: 5px solid transparent;
        background-color: transparent;
        transition: all .4s ease;
        -webkit-animation: to-top 1.2s forwards;
        animation: to-top 1.2s forwards;

        &.active {
          color: #2e6495!important;
          border-color: #f5c93c;
        }
      }
      &-right {
        padding: 97px 47px;
        background-color: rgba(47,46,65,.9);
        overflow-y: auto;
      }
      &-links {
        display: flex;
        flex-direction: column;
        grid-gap: 30px;

      }

      &-link {
        display: inline-block;
        position: relative;
        z-index: 3;
        text-decoration: none;
        -webkit-animation: to-top 1.2s forwards;
        animation: to-top 1.2s forwards;

        &:hover {
          & * {
            color: #f5c93c;
          }
          & svg {
            transform: rotate(180deg);
            stroke: #f5c93c;
          }
        }

        &-content {
          display: inline-flex;
          grid-gap: 20px;

          & svg {
            height: 20px;
            width: 20px;
            stroke: #fff;
            transition: all .3s linear;
          }
        }
        &-title {
          display: inline-block;
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 22px;
          line-height: 1;
          text-transform: uppercase;
          color: #fff;
          transition: color .2s linear;
        }
        &-subtitle {
          font-weight: 300;
          font-size: 18px;
          color: #fff;
          transition: color .2s linear;
        }
      }
    }
  }

  &-inner {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 20;
  
    padding: 20px 0;

    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);

    & .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-logo {
    display: block;
    height: 60px;
    width: auto;
  }

  &-nav {
    display: flex;
    align-items: center;
    grid-gap: 35px;

    &-link {
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      color: #080A1C;
      text-transform: uppercase;
      position: relative;
      transition: color .3s ease-in-out;

      &::after {
        content: '';

        display: block;
        width: 0;
        height: 2px;

        position: absolute;
        left: 0;
        top: calc(100% + 4px);

        background-color: #128FFF;
        transition: width .3s ease-in-out;
      }

      &:hover {
        color: #128FFF;
        &::after {
          width: 100%;
        }
      }
    }
  }

  &-right .btn-links{
    display: flex;
    grid-gap: 20px;
  }

  &-submit {
    height: auto !important;

    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    color: #FFFFFF;

    padding: 20px 30px 18px !important;
    border-radius: 10px;
    background: #128FFF;
  }

  &-to-auth {
    height: auto !important;

    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    color: #080A1C;

    padding: 20px 30px 18px;

    background-color: transparent;
    border: 1px solid #080A1C;
    border-radius: 10px;

    &:hover,
    &:focus {
      color: #fff;
      border-color: #080A1C;
      background-color: #080A1C;
    }
  }
}

@keyframes to-top {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}