.projectDetail-landing {
  background: #EDF2F7;

  & .header-landing {
    margin-bottom: 20px;
    &-inner {
      position: static;
    }
  }

  & .landing-title {
    margin-bottom: 30px;
    font-size: 20px;
  }

  &-inner {
    min-height: 80vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 140px;
  }

  &-content {
    & .prejectDetail-info:last-child {
      margin-bottom: 0;
      & .prejectDetail-info-text {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }

  & .prejectDetail-info {
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: center;
    grid-gap: 35px;
    margin-bottom: 20px;

    & .prejectDetail__svg {
      margin-top: -45px;
    }
    
    &-text {
      display: block;
      min-height: 75px;
      height: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
      color: #080A1C;

      padding-bottom: 20px;
      border-bottom: 1px solid #A1BB61;
    }
  }

  &-preview {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &-presentation {
    width: 447px;
    height: 637px;
    margin-right: 40px;
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all .3s ease-in-out;
    
    & img {
      width: 100%;
      height: 100%;
      transition: all .3s ease-in-out;
    }

    &::after {
      content: '';

      display: block;
      height: 100%;
      width: 100%;

      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;

      background-color: rgba(#080A1C, .4);
      transition: all .3s ease-in-out;
    }

    &:hover {
      & img {
        width: 110%;
        height: 110%;
      }
      &::after {
        opacity: 1;
      }
    }
  }

  &-download,
  &-controls button {
    height: 50px;
    width: 50px;
    
    display: flex;
    align-items: center;
    justify-content: center;

    background: #fff;
    border-radius: 10px;
    border: none;
  }
  &-download {
    background: #002B6F;
    margin-bottom: 22px;
    margin-top: 31px;

    &[disabled] {
      pointer-events: none;
      background-color: #D6E1EC;
    }
  }
  &-controls {
    position: absolute;
    top: 50%;
    left: -40px;
    right: -40px;

    display: flex;
    justify-content: space-between;

    & button:first-child {
      transform: rotate(180deg);
      opacity: .5;
      & svg {
        fill: rgba(160, 174, 192, 0.5);
      }
    }
  }
}

.react-pdf__Document {
  position: relative;

  display: flex;
  align-items: center;
}

.prejectDetail__pdf__button {
  position: absolute;
  border-radius: 10px;
}

.pdf__button__prev {
  left: -24px;
}

.pdf__button__next {
  right: -24px;
}

.pdf__loader {
  background-color: white;
  height: 630px;
  width: 449px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfModal {

  & .ant-modal-content {
    width: 700px;
  }

  & .rpv-core__viewer {
    height: 800px !important;
  }

  & .pdf__modal__pages {
    position: fixed;
  
    top: 80px;
  
    left: 200px;
  
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
  
    color: rgba(255, 255, 255, 0.8);
  }
  
  & .pdf__modal__close-svg {
    position: fixed;
  
    top: 80px;
  
    right: 200px;
  
    cursor: pointer;
  }
}

.pdf_empty {
  width: 447px;
  height: 632px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 65px;

  background: rgba(214, 225, 236, 0.2);

  &_svg {
    width: 235px;
  }
  &_title {
    font-weight: 400;
    font-size: 20px;
    color: #A0AEC0;
  }
}
