.successStories {
  & .header-landing{
    margin-bottom: 40px;
    &-inner {
      position: static !important;
    }
  }

  &-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 33px;
    padding-bottom: 100px;
  }

  &-item {
    padding: 25px;
    background: rgba(237, 242, 247, 0.8);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 60%;
    position: relative;
  }

  &-textBlock {
    position: absolute;
    right: 20px;
    bottom: 18px;

    display: inline-block;
    padding: 9px 22px 10px;

    background: #2C4566;
    border-radius: 5px;
  }

  &-title {
    font-weight: 400;
    font-size: 25px;
    line-height: 1.4;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  &-obl {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.1;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  &-sum {
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 0;
  }
}