.create-project {
  // -----  Basket SideBar
  & .basket-toggle {
    width: 100%;
    cursor: pointer;

    display: flex;
    align-items: center;
    grid-gap: 20px;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    padding: 15px 30px;
    border: none;
    background-color: transparent;
    transition: all .3s ease-in-out;

    & h2.title  {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 0px !important;
      transition: all .3s ease-in-out;

      color: #2D3748;
    }

    & .count {
      min-width: 30px;
      height: 30px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: #18CEC9;
      border-radius: 10px;
      transition: all .3s ease-in-out;

      & p {
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;
        margin-bottom: 0 !important;
      }
    }
  }
  & .basket-inner {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & .basket-block {
    width: 475px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 0;
    right: -500px;
    z-index: 10;

    padding: 70px 30px 0 30px;
    border: 2px solid transparent;
    outline: none;
    background-color: #EDF2F7;
    transition: all .3s linear;
    
    &.notEmpty {
      right: -10px;
    }
    & .kinds-list {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 30px;

      & .kind-data {
        max-width: 306px;
        background-color: #fff;
      }

      & .kind-card {
        min-width: 405px;
        width: max-content;
        min-height: 108px;

        display: grid;
        grid-template-columns: 108px 1fr;
        background-color: #fff;

        & .image-card {
          height: 100%;
          width: 108px;
          background-color: #A0AEC0;
        }

        & .name,
        & .price,
        & .center > div {
          min-height: 36px;
          grid-gap: 3.8px;
          padding: 8px 15px;
        }

        & .name {
          font-size: 14px;
        }

        & .center {
          & span {
            font-size: 12px;
          }

          & .beds svg {
            height: 25px;
            width: 25px;
          }

          & .temperature svg {
            width: 16px;
            height: 17px;
          }

          & .size svg {
            height: 20px;
            width: 20px;
          }
        }
      }

      & .kind-card.plot {
        & .name.blue {
          font-weight: 500;
          font-size: 14px;
          color: #128FFF;
        }

        & .center {
          padding: 8px 15px;
        }

        & p.location {
          font-weight: 400;
          font-size: 14px;
          color: #2D3748;
          margin-bottom: 0px !important;
        }
      }
    }


    & .go-to-basket {
      background: #18CEC9;
      border: none;
      border-radius: 10px;

      padding-left: 50px;
      padding-right: 50px;

      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
    }
  }
}