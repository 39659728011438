.create-project {
  &-inner {
    width: 100%;
    transition: all .3s linear;

    &.active {
      width: calc(100% - 500px);
    }
  }
  // -----
  & .ant-modal-mask {
    background: rgba(0, 43, 111, 0.8) !important;
  }

  &-select-item-btn {
    height: fit-content !important;
    padding: 10px 15px !important;
    background: #128FFF !important;
    border-radius: 5px !important;

    & span {
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #FFFFFF !important;
    }

    &:hover {
      background: rgba(#128FFF, .8) !important;
    }
  }

  & .header-landing {
    margin-bottom: 20px;

    &-inner {
      position: static;
    }
  }

  & .image-card {
    overflow: hidden;
    position: relative;

    & img {
      width: 100%;
      min-height: 100%;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & .image-card-preview {
    overflow: hidden;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: auto;
      max-height: 100%;
      position: absolute;
    }
  }

  &-titles {
    margin-bottom: 20px;
  }

  &-inner {
    min-height: 60vh;
    position: relative;
    overflow: hidden;
  }

  &-blocks {
    margin-bottom: 100px;
  }

  & .step-title {
    font-weight: 400;
    font-size: 20px;
    color: #2D3748;
    margin-bottom: 20px;
  }

  & .step-cards {
    display: flex;
    justify-content: center;

    & > div {
      width: 100%;
    }
  }

  & .states-block {
    width: 100%;

    &.active {
      width: calc(100% - 475px);
    }
  }


  // regForm
  & .regForm {
    & h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      color: #2D3748;
      margin-bottom: 0px !important;
    }

    & .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
}