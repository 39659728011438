.create-project {

  // ----- Progress Bar
  &-header {
    margin-bottom: 45px;
  }

  & .steps {
    display: flex;
    grid-gap: 15px;
    margin-bottom: 40px;

    & .step {
      width: 52px;
      height: 10px;

      background: #E2E8F0;
      border: 0;
      border-radius: 20px;
      outline: 5px solid transparent;
      transition: all .2s ease-in-out;

      &.active {
        cursor: pointer;
      }
    }

    & .step:first-child {
      background: #00B4FF;
      outline: 5px solid rgba(0, 180, 255, 0.2);
    }

    & .step:nth-child(2).active {
      background: #00D1ED;
      outline: 5px solid rgba(0, 209, 237, 0.2);
    }

    & .step:nth-child(3).active {
      background: #00E6BB;
      outline: 5px solid rgba(0, 230, 187, 0.2);
    }

    & .step:nth-child(4).active {
      background: #90F488;
      outline: 5px solid rgba(144, 244, 136, 0.2);
    }

    & .step:last-child.active {
      background: #F9F871;
      outline: 5px solid rgba(249, 248, 113, 0.2);
    }
  }
}