.stateSupportMeasures {
  background: #EDF2F7;

  & .header-landing-inner {
    position: static;
  }
  &-block {
    padding: 20px 0 140px;
    position: relative;
  }
  &-inner {
    min-height: 80vh;
  }
  &-header {
    margin-bottom: 50px;
  }

  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;

    & > div {
      display: flex;
      flex-direction: column;
      grid-gap: 30px;
    }

    &-item {
      padding-left: 26px;
      position: relative;

      &::before {
        content: '';
        width: 12px;
        height: 12px;

        position: absolute;
        left: 0;
        top: 5px;

        border: 3px solid #28C397;
        border-radius: 50%;
      }

      & p,
      & a {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.6;
        color: #000000;
        margin-bottom: 0;
      }

      & a {
        color: #128FFF;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}