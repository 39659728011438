/* @font-face {
    src: url("assets/fonts/SFProDisplay-Medium.ttf");
    font-family: SFProDisplay-Medium;
}

@font-face {
    src: url("assets/fonts/SFProDisplay-Bold.ttf");
    font-family: SFProDisplay-Bold;
}

@font-face {
    src: url("assets/fonts/SFProDisplay-Regular.ttf");
    font-family: SFProDisplay-Regular;
} */

@import url('./assets/fonts/SFPro/stylesheet.css');

*, body, .sfRegular {
    font-family: 'SF Pro Display', sans-serif;
}

pre {
    font-family: 'SF Pro Display', sans-serif !important;
}

h1, h2, h3, h4, b, .sfBold {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: bold;
}

.sfMedium {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 500;
}

.ant-layout-sider .ant-menu-item {
    display: flex;
    align-items: center;
}

.bgOrange {
    background-color: #FFBA7B !important;
    color: #fff !important;;
}

.bgGreen {
    background-color: #38B836 !important;
    color: #fff !important;;
}

.bgBlue {
    background-color: #338DE0 !important;
    color: #fff !important;;
}

.orangeText {
    color: #FFBA7B !important;
}

.greenText {
    color: #38B836 !important;
}

.blueText {
    color: #338DE0 !important;
}

.blueIconFill svg path {
    fill: #338DE0
}

.greenIconStroke svg path {
    fill: #38B836
}

.redIconStroke svg path {
    fill: #ff8181
}

.yellowIconStroke svg path {
    fill: #338de0
}

.lableToInputArrow {
    margin-right: 14px;
    margin-left: 8px;
}

.modalFooter {
    margin-top: 15px;
}

.ant-select-selector {
    border-radius: 3px;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    /* min-height: 40px !important; */
}


.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
}

.formLabelBold {
    color: #3B4E77;
    font-family: SFProDisplay-Bold, sans-serif;
    font-size: 16px
}

.deleteMarker {
    color: #3b99fc;
    cursor: pointer;
}

.filterTasks .ant-select-arrow {
    top: 34% !important;
}

.tableByStatus a {
    color: #000
}

.searchField .ant-input-group-addon {
    background: #fff;
    border: none;
    text-align: left;
}

.searchField .ant-select {
    min-width: 174px;
}

.searchField .ant-input-group .ant-input {
    border-left: 1px solid #d9d9d9;
    height: 40px;
}

.ant-input-group-addon .ant-input-search-button, .ant-input-group-addon .ant-input-search-button:hover {
    background: transparent;
    box-shadow: none;
}

.serachLinkResult {
    background: #fff;
    float: left;
    text-align: left;
    padding: 10px 20px;
    font-size: 18px;
    margin: 10px 15px;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 3%);
    width: 91%;
}

.rbc-time-content, .rbc-month-view {
    cursor: pointer;
}

.rbc-event {
    background-color: #3174ad !important;
}

.rbc-event-content a {
    color: #fff;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td,
.rbc-toolbar .rbc-toolbar-label,
div.rbc-date-cell > a,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td, .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    color: black;
}