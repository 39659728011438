.projectsLanding {
  background: #EDF2F7;

  & .header-landing {
    &-inner {
      position: static;
    }
  }
  
  &-block {
    padding: 20px 0 140px;
    position: relative;
  }

  &-inner {
    min-height: 80vh;
  }

  &-view-filter {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    
  }
  &-filter-view-btn {
    width: 60px;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 10px;
    background: rgba(226, 232, 240, 0.5);
    transform: rotate(180deg);
    transition: all .2s ease-in-out;

    &.map svg {
      transform: rotate(180deg);
    }

    & svg {
      transition: all .2s ease-in-out;
    }

    &.type {
      &-map svg,
      &-grid svg {
        stroke: #C3CDDA;
      }
      &-list svg {
        fill: #C3CDDA;
      }
    }

    &:hover {
      background: rgba(157, 194, 224, 0.3);
      &.type {
        &-map svg,
        &-grid svg {
          stroke: rgba(#85AED2, .8);
        }
        &-list svg {
          fill: #85AED2;
        }
      }
    }

    &.active {
      background: rgba(157, 194, 224, 0.5);
      &.type {
        &-map svg,
        &-grid svg {
          stroke: #fff;
        }
        &-list svg {
          fill: #fff;
        }
      }
    }
  }
}