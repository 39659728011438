.create-project {

  // ----- Step 3
  & .kinds-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;

    & .image-card {
      background-color: #A0AEC0;
      height: 200px;
    }

    & .kind-data {
      min-width: 345px;
      border: 0.3px solid #A0AEC0;
    }

    & .name,
    & .price,
    & .center > div {
      width: 100%;
      min-height: 45px;

      border-style: solid;
      border-color: #A0AEC0;
      border-width: 0 .3px 0 .3px;

      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 5px;

      padding: 12px 20px;


      & span {
        font-weight: 400;
        font-size: 14px;
        color: #96A0B5;
      }
    }

    & .name {
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      justify-content: start;
    }

    & .center {
      display: flex;
      border-style: solid;
      border-color: #A0AEC0;
      border-width: .3px 0 .3px 0;

      & .beds svg {
        height: 30px;
        width: 30px;
      }

      & .temperature {

        svg {
          width: 20px;
          height: 20px;
        }
      }

      & .size svg {
        height: 24px;
        width: 24px;
      }
    }

    & .price {
      justify-content: space-between;

      & button {
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        cursor: pointer;
        height: -webkit-fill-available;

        & svg {
          fill: #96A0B5;
        }
      }
    }
  }

}