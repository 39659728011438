._3lLk3:nth-child(1) {
  min-width: 300px !important;
}

._WuQ0f:nth-child(1) {
  min-width: 300px !important;
}

._3lLk3:nth-child(2),
._3lLk3:nth-child(3),
._2eZzQ {
  display: none;
}

._WuQ0f:nth-child(3),
._WuQ0f:nth-child(5) {
  display: none;
}

._nI1Xw > div:nth-child(2) {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gantDiv > div > div > div {
  color: #338DE0;
}

.gantDiv > div > div > div > div:first-child {
  ._WuQ0f{
    height: 0;
    opacity: 0;
  }
}

._3_ygE:after {
  content: 'Проекты и задачи';
  display: block;
  opacity: 1;
  padding-left: 15px;
  position: absolute;
  font-weight: bold;
  top: 15px;
}

._2QjE6 {
  margin-right: 5px;
  margin-left: 10px;
}

._2TfEi + div {
  color: #121F3E;
}