.create-project {
  // ----- Step 3 Styles
  & .types-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;

    & button {
      flex: 1;
      min-width: 380px;
      max-width: 420px;
    }

    & .image-card {
      height: 245px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      &::after {
        content: '';

        display: block;
        height: 55%;
        width: 100%;

        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba(0, 43, 111, 0) 0%, #002B6F 100%);
      }

      & span {
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;

        position: absolute;
        bottom: 50px;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        pointer-events: none;
      }
    }
  }
  
  & .types-list {
    & .image-card {
      min-height: 200px;
      max-height: 220px;
      height: auto;
      padding-bottom: 14%;

      & span {
        bottom: 30px;
      }
    }
  }
}