.comment {
  width: 100%;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  &__body {
    background: rgba(51, 141, 224, 0.08);
    border: 1px solid #F2F6FF;
    padding: 15px;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15px;
    color: #121F3E;
    text-align: start;
    margin-bottom: 5px;
  }
  &__header {
    display: flex;
    align-items: center;
    gap: 45px;
    margin-bottom: 10px;
    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: start;
      margin-bottom: 0;
    }
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.15px;
      color: #A0A0A0;
    }
  }
  &__actions {
    grid-column-start: 2;
    display: flex;
    padding-left: 5px;
    gap: 30px;
    button {
      background: none;
      padding: 0;
      border: none;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.15px;
      color: #A0A0A0;
      cursor: pointer;
      &:hover {
        color: #000;
      }
    }
  }
  .avatar {
    border: 2px solid #FFFFFF;
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
  &.child {
    border: none;
    background: none;
    padding: 0 0 0 57px ;
    display: block;
  }
}
div {
  text-align: start;
}
.comment__input {
  border: 1px solid #E1E8EF;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 13px 15px;
  width: 100%;
  margin-bottom: 10px;
}