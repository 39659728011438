.calendar__sync {
    position: absolute;
    top: 50px;
    right: 20px;

    display: inline-block;
    margin: 0;
    margin-left: 0px;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: .375rem 1rem;
    border-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    line-height: normal;
    white-space: nowrap;
    cursor: pointer;

    & span {
        font-size: 16px;
        color: #373a3c;
    }
}