.landing-tableProjects{

  // Vremenno
  &-projects-header {
    display: block !important;
  }
  &-projects-table.grid {
    padding-top: 0 !important;
    border-top: 0 !important;
  }
  // &-filter,
  // &-projects-sort {
  //   display: none !important;
  // }
  /////


  &-filter {
    grid-gap: 10px;
    & > *,
    & .ant-select-selector {
      border-radius: 6px !important;
      min-height: 40px !important;
    }
    & .price-filter {
      max-width: 330px;
    }
    & button {
      display: flex;
      align-items: center;
      grid-gap: 13px;
      background: #9DC2E0;
  
      & svg {
        top: 0;
      }
    }
  }
  & .image-card {
    overflow: hidden;
    position: relative;
  
    & img {
      width: 100%;
      min-height: 100%;
      
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & .pdf_svg {
      height: 80%;
      
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-projects {
    &-header h4 {
      color: #128FFF;
      font-weight: 500;
      font-size: 16px;
    }
    &-sort {
      display: flex;
      align-items: center;
      grid-gap: 25px;
  
      & h5,
      & button {
        padding: 0 !important;
        margin: 0 !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;
        color: #A0AEC0;
      }
  
      & button {
        &:hover {
          background: transparent !important;
          color: #128FFF;
        }
      }
    }
  }
  &-projects-table {
    min-height: 400px;
    & .status {
      position: relative;
      padding-left: 20px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      &.yellow::before {
        background: #FCC865;
      }
      &.red::before {
        background: #FF575F;
      }
      &.green::before {
        background: #28C397;
      }
      &.gray::before {
        background: #96A0B5;
      }
    }
    &.list {
      & .landing-tableProjects-projects {
        &-header {
          padding-left: 25px;
        }
        &-list {
          max-height: 665px;
          overflow-y: auto;
          background: #FFFFFF;
          padding: 17px 25px;
        }
        &-row {
          padding-bottom: 26px;
          border-bottom: 1px solid #E7EDF2;
          & .title {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.2;
            color: #A0AEC0;
            margin-bottom: 8px;
      
            &.project-name {
              color: #051A2D;
              font-weight: 700px;
              transition: all .2s ease-in-out;

              &:hover {
                color: #1890ff;
              }
            }
          }
        }
        &-link {
          display: grid;
          grid-template-columns: 80px 1fr;
          grid-gap: 20px;
        }
      }
      & .image-card {
        width: 80px;
        height: 63px;
        border-radius: 5px !important;
        overflow: hidden !important;
      }
    }
    &.grid {
      padding-top: 20px;
      border-top: 1.2px solid #E1E8EF;
      & .image-card {
        height: 100%;
        width: 100%;
        
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        & img {
          top: 58%;
          width: 110% !important;
          transition: all .2s linear;
        }
        
        &::after {
          content: '';
          display: block;
          height: 100%;
          width: 100%;
  
          position: absolute;
          top: 0;
          left: 0;
          opacity: .2;
          background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
          transition: all .2s linear;
        }

        &::before {
          content: '';

          display: block;
          height: 100%;
          width: 100%;

          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;

          background-color: rgba(8, 10, 28, 0.3);
        }

        &:hover {
          & img {
            width: 115% !important;
          }
          &::after {
            opacity: .4;
          }
        }
      }
      & .landing-tableProjects-projects {
        &-list {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 30px;
        }
        &-card {
          height: 286px;
          padding: 95px 20px 20px;
          overflow: hidden;
          position: relative;
          z-index: 1;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
          & .project-name {
            font-weight: 700;
            font-size: 16px;
            line-height: 1.2;
            color: #FFFFFF;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          & p {
            font-weight: 400;
            font-size: 13px;
            line-height: 1.2;
            color: #FFFFFF;
            margin-bottom: 10px;
  
            & span {
              font-weight: 400;
              font-size: 16px;
            }
      
          }
        }
      }
    }
  }
}