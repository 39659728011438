.task-add-edit-modal {
  .ant-modal-header {
    background: #338DE0;
  }
  .ant-modal-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.15px;
    color: #FFFFFF!important;
  }
  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.15px;
    color: #121F3E;
  }
  .ant-btn.ant-btn-link {
    border: 1px solid #338DE0;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px!important;
    span {
      color: #338DE0;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.15px;
    }
  }
  .ant-input {
    box-sizing: border-box;
    border-radius: 8px;
  }
  .ant-picker {
    border-radius: 8px!important;
    padding: 10px 15px 10px 11px!important;
    line-height: 20px;
    margin: 0!important;
    height: 48px!important;
  }
  .ant-select-selector, .ant-select {
    border-radius: 8px!important;
  }

  .ant-select-selection-placeholder, .ant-select-selection-item {
    text-align: start;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.41506 6.65079L7.24084 11.1142C7.60026 11.5335 8.23156 11.5821 8.65089 11.2227C8.68977 11.1893 8.72603 11.1531 8.75935 11.1142L12.5851 6.65079C12.9446 6.23147 12.896 5.60017 12.4767 5.24074C12.2954 5.08539 12.0646 5 11.8259 5L4.17432 5C3.62203 5 3.17432 5.44772 3.17432 6C3.17432 6.23871 3.25971 6.46955 3.41506 6.65079Z' fill='%23338CDE'/%3E%3C/svg%3E%0A");
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 20px;
  }

  .ant-switch {
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    .ant-switch-handle::before {
      background-color: #E1E8EF;
    }
  }
  .ant-switch-checked {
    background-color: #fff;
    .ant-switch-handle::before {
      background-color: #28C397;
    }
  }
  .ant-select-selection-overflow {
    gap: 5px;
  }

  .disabled-select {
    .ant-select-selector, .ant-select {
      border: none!important;
    }
    pointer-events: none;
    .ant-select-dropdown {
      display: none;
    }
  }
  textarea {
    border-radius: 8px!important;
  }
  textarea:focus {
    border: 1px solid #40a9ff!important;
  }
  .contragents-select {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  .contragent-card  {
    display: flex;
    flex-direction: column;
    color: #080A1C;
    background: rgba(51, 141, 224, 0.08);
    padding: 9px 52px 9px 15px;
    border-radius: 8px;
    position: relative;
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.15px;
      color: #121F3E;
    }
    .contragent-company {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0.15000000596046448px;
    }
  }
  .contragent-card-close {
    padding: 0; 
    margin: 0;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    pointer-events: all;
    svg {
      width: 10px;
      height: 10px;
      path {
        fill: #3C4E77;
      }
    }
  }
}
.taskView-profile-link {
  display: flex;
  flex-direction: column;
  background: rgba(51, 141, 224, 0.08);
  border: 1px solid #F2F6FF;
  box-sizing: border-box;
  border-radius: 8px;
  width: fit-content;
  padding: 9px 15px;
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.15px;
    color: #121F3E;
  }
  .organization {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.15000000596046448px;
  }
}

.archive__select {
  & .ant-select-selector {
    background: #FF7B7B !important;
    color: white !important;
    border-radius: 3px !important;
    border: none !important;
    box-shadow: none !important;

    &:hover,
    &:focus {
      border: none !important;
      box-shadow: none !important;
      background: #F19B99 !important;
    }

    & .ant-select-selection-item {
      color: white !important;
    }
  }
}

.select__users,
.select__projects,
.select__organizations,
.select__date {
  width: 250px !important;
}

.archive {
  float: right;
  width: 185px;
  height: 48px;
  margin-left: 43px;
}

.archive_actions svg{
  fill: #96A0B5;
  transition: all .2s linear;

  &.view:hover {
    fill: #38B836 !important;
  }
  &.delete:hover {
    fill: #FF7B7B !important;
  }
}

@media screen and (max-width: 1919px) {
  .archive {
    width: 150px;
    padding: 7px 8px !important;
    
    & span {
      font-size: 13px !important;
    }
  }

  .select__users,
  .select__projects,
  .select__organizations,
  .select__date {
    width: 250px !important;
  }
}