.rdrDefinedRangesWrapper {
    display:none !important;
}
.rdrDateDisplayWrapper{
    display: none;
}
.rdrMonthAndYearWrapper{
    display: none !important;
}

.dashboard__first-row {
    margin-bottom: 30px;
}

.dashboard__first-row__chart-holder {
    position: relative;

    width: 450px;
    height: 332px;
    border: 1px solid rgba(150, 160, 181, 0.3);
    margin-right: 30px;
    border-radius: 10px;
}
  
.dashboard__second-row__chart-holder {
    position: relative;

    width: 690px;
    height: 332px;
    border: 1px solid rgba(150, 160, 181, 0.3);
    margin-right: 30px;
    border-radius: 10px;
}
  
.bar__canvas,
.doughnut-tasks__canvas {
    width: 438px !important;
    height: 310px !important;
    padding-left: 20px !important;
}

.doughnut__total {
    width: 100px;

    text-align: center;

    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    text-align: center;

    color: #414141;

    z-index: 2;

    user-select: none;
}

.doughnut-tasks__total {
    position: absolute;

    top: 175px;
    left: 96px;
}

.doughnut-tasks__legend-holder {
    position: absolute;

    width: 130px;
    height: 201px;

    top: 33px;
    right: 6px;

    padding-left: 10px;

    pointer-events: none;
    user-select: none;

    & .doughnut-tasks__legend {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;

        color: #414141;

        margin: 0;
        margin-bottom: 27px;

        text-align: left;
    }

    & .doughnut-tasks__overdue-tasks {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        & .overdue__count {       
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;

            color: #414141;

            margin: 0;
            padding: 0;
        }

        & .overdue__text {
            margin: 0;
            padding: 0;

            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            /* identical to box height */
            // letter-spacing: 0.15px;

            color: red;
        }
    }
}
  
.doughnut-priorities__canvas {
    width: 438px !important;
    height: 310px !important;
    padding-left: 20px !important;
}

.doughnut-priorities__total {
    position: absolute;

    top: 175px;
    left: 105px;
}

.doughnut-priorities__legend-holder {
    position: absolute;

    width: 140px;
    height: 250px;

    top: 60px;
    right: 11px;

    background: #FFFFFF;

    user-select: none;
    // border: 1px solid green;

    & .doughnut-priorities__legend {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;

        color: #414141;

        margin: 0;
    }

    & .priority {
        width: 86px;
        height: 32px;
        border-radius: 8px;
        padding: 9px 18px;

        margin-bottom: 18px;

        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
    }

    & .high-priority {
        background: #FFEEEF;
        color: #FF575F;
    }

    & .mid-priority {
        background: #FFF8EC;
        color: #FCC865;
    }

    & .low-priority {
        background: #E9F9F5;
        color: #28C397;
    }
}
  
.bar-tasks__canvas,
.bar-overdue__canvas {
    width: 678px !important;
    height: 310px !important;
    padding-left: 20px !important;
}

.bar-overdue__avatars-holder {
    position: absolute;
    display: flex;

    width: 100%;
    height: 26px;

    bottom: 5px;

    padding-left: 116px;

    // border: 1px solid green;

    & .bar-overdue__avatar {
        height: 24px;
        width: 24px;

        border-radius: 50%;

        background: yellow;

        margin-right: 74.5px;
    }

    & .bar-overdue__avatar:last-of-type {
        margin-right: 0;
    }
}

@media screen and (max-width: 1919px) {
    .dashboard__first-row {
        margin-bottom: 15px;
    }

    .dashboard__first-row__chart-holder {
        width: 350px;
        height: 332px;
        border: 1px solid rgba(150, 160, 181, 0.3);
        margin-right: 15px;
        border-radius: 10px;
    }
      
    .dashboard__second-row__chart-holder {
        width: 532px;
        height: 332px;
        border: 1px solid rgba(150, 160, 181, 0.3);
        margin-right: 15px;
        border-radius: 10px;
    }
      
    .bar__canvas,
    .doughnut-tasks__canvas {
        width: 95% !important;
        height: 310px !important;
        padding-left: 20px !important;
    }
      
    .doughnut-priorities__canvas {
        width: 95% !important;
        height: 310px !important;
        padding-left: 20px !important;
    }
      
    .bar-tasks__canvas,
    .bar-overdue__canvas {
        width: 95% !important;
        height: 310px !important;
        padding-left: 20px !important;
    }
    
    .doughnut__total {
        width: 100px;
    
        text-align: center;
    
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
    
        color: #414141;
    
        z-index: 2;
    
        user-select: none;
    }
    
    .doughnut-tasks__total {
        position: absolute;
    
        top: 175px;
        left: 51px;
    }
    
    .doughnut-tasks__legend-holder {
        position: absolute;
    
        width: 130px;
        height: 201px;
    
        top: 33px;
        right: 7px;
    
        padding-left: 10px;
    
        pointer-events: none;
        user-select: none;
    
        & .doughnut-tasks__legend {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
    
            color: #414141;
    
            margin: 0;
            margin-bottom: 27px;
    
            text-align: left;
        }
    }

    .doughnut-priorities__total {
        position: absolute;
    
        top: 175px;
        left: 53px;
    }
    
    .doughnut-priorities__legend-holder {
        position: absolute;
    
        width: 150px;
        height: 250px;
    
        top: 60px;
        right: 3px;

        padding-left: 30px;
    
        background: #FFFFFF;
    
        user-select: none;
    
        & .doughnut-priorities__legend {
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
    
            color: #414141;
    
            margin: 0;
        }
    
        & .priority {
            width: 86px;
            height: 32px;
            border-radius: 8px;
            padding: 9px 18px;
    
            margin-bottom: 18px;
    
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
        }
    
        & .high-priority {
            background: #FFEEEF;
            color: #FF575F;
        }
    
        & .mid-priority {
            background: #FFF8EC;
            color: #FCC865;
        }
    
        & .low-priority {
            background: #E9F9F5;
            color: #28C397;
        }
    }

    .bar-overdue__avatars-holder {
        position: absolute;
        display: flex;
    
        width: 100%;
        height: 26px;
    
        bottom: 5px;
    
        padding-left: 96px;
    
        & .bar-overdue__avatar {
            height: 24px;
            width: 24px;
    
            border-radius: 50%;
    
            margin-right: 40px;
        }
    
        & .bar-overdue__avatar:last-of-type {
            margin-right: 0;
        }
    }
}

.tasks-today {

    width: 100%;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;

        & .tasks-today__h3 {
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            
            /* identical to box height */
            letter-spacing: 0.15px;
            
            color: #121F3E;
        }
    }
    &__control {
        display: flex;
        grid-gap: 25px;

        & button {
        height: 24px;
        cursor: pointer;

        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        margin: 0;

        &:hover svg,
        &:focus svg {
            fill: #128FFF;
        }
        }

        & button:first-child {
        transform: rotate(180deg);
        }

    }
    &__slider {
        display: flex;
        overflow-x: auto;
        grid-gap: 20px;
        scroll-behavior: smooth;

        padding: 0 20px;

        width: 100%;
        // margin: 0px -1000px;
        // padding: 10px 1000px;
        transition: all .3s ease-in-out;

        -ms-overflow-style: none; /* IE 11 */
        scrollbar-width: none; /* Firefox 64 */

        &::-webkit-scrollbar {
        display: none;
        }
    }

    &__card {
        width: 315px;
        flex-shrink: 0;
    }

    & .info {
        margin-left: auto !important;
    }

    &__empty {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 1919px) {
    .tasks-today {
        width: 100%;

        &__card {
            width: 240px;
            flex-shrink: 0;
        }

        &__slider {
            grid-gap: 15px;
        }

        .expire {
            font-size: 12px;
        }
    }
}

.priority-holder {
    width: 86px;
    height: 32px;
    border-radius: 8px;
    padding: 9px 18px;

    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}

.high-priority {
    background: #FFEEEF;
    color: #FF575F;
}

.mid-priority {
    background: #FFF8EC;
    color: #FCC865;
}

.low-priority {
    background: #E9F9F5;
    color: #28C397;
}