.sending-application {
  & .basket {
    margin-bottom: 50px;
  }

  & .plotSearch {
    &-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      border: 0.5px solid #A0AEC0;
      padding: 20px 40px 20px 20px;
      margin-bottom: 25px;

      & .left {
        display: flex;
        grid-gap: 30px;
      }
    }
    &-preview {
      height: 145px;
      width: 266px;
    }
    &-content {
      padding: 0px;
      border: none;
    }
    &-subtitle {
      font-size: 20px;
      margin-bottom: 4px;
    }
    &-title {
      font-size: 20px;
      margin-bottom: 16px;
    }
    &-footer {
      padding: 0px;
      border: none;
      padding-top: 25px;
    }
    &-price {
      font-weight: 500;
      font-size: 24px;
      color: #2D3748;
    }
  }

  & .construction {
    &-item {
      border: 0.5px solid #A0AEC0;
      padding: 20px 40px 20px 20px;
      margin-bottom: 25px;
    }

    &-basicInfo {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 20px;

      & .left {
        display: flex;
        grid-gap: 30px;
      }

      & .preview {
        height: 145px;
        width: 266px;

        background-color: #A0AEC0;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      & .title {
        font-weight: 600;
        font-size: 20px;
        color: #2D3748;
        margin-bottom: 38px;
      }

      &-values {
        display: flex;
        grid-gap: 52px;

        & p.value {
          font-weight: 400;
          font-size: 16px;
          color: #96A0B5;
          margin-bottom: 10px;

          & span {
            color: #000000;
          }
        }
      }
    }

    &-description {
      color: #2D3748;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 30px;
    }

    &-count-and-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & .price {
        font-weight: 500;
        font-size: 24px;
        color: #2D3748;
        margin-bottom: 25px;
      }

      & .count-control {
        display: grid;
        grid-template-columns: repeat(3, 40px);
        grid-template-rows: 40px;

        & button,
        & .count {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0.5px solid rgba(160, 174, 192, 0.5);
        }

        & button {
          color: #fff;
          background: rgba(160, 174, 192, 0.5);
          transition: all .2s ease-in-out;
          cursor: pointer;

          &:hover {
            background-color: rgba(160, 174, 192, 0.8);
          }
        }

        & .count {
          font-weight: 500;
          font-size: 16px;
          color: #2D3748;
        }
      }
    }
  }

  & .companies {
    &-title {
      font-weight: 600;
      font-size: 16px;
      color: #2D3748;
      margin-bottom: 8px;
    }

    &-table {
      & .ant-table  {
        background-color: transparent;
      }

      & table,
      & .ant-table-container,
      & .ant-table-cell {
        border: none !important;
      }
      & .ant-table-cell {
        font-weight: 400;
        font-size: 16px;
        color: #96A0B5;

        padding: 10px 0px;
        background-color: transparent;
        border-bottom: 0.5px solid #A0AEC0 !important;
      }

      & .ant-table-tbody {
        & .ant-table-cell {
          color: #2D3748;
        }
        & tr:last-of-type .ant-table-cell{
          border-bottom: none !important;
        }
      }

      & .title {
        font-weight: 400;
        font-size: 16px;
        color: #2D3748;
        margin-bottom: 4px;
      }

      & .stars {
        display: flex;

        & svg {
          height: 16px;
          width: 16px;
        }
      }

      & a {
        font-weight: 400;
        font-size: 16px;
        color: #128FFF;
      }

      & button.ant-btn-primary {
        height: auto !important;
        padding: 10px 15px;
        background: #128FFF;
        border-radius: 5px;

        & span {
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
        }

        &.active {
          background-color: #28C397 !important;
          border-color: #28C397 !important;
        }
      }

      & .checkbox-variant {
        margin: 0px !important;
      }
    }
  }

  & .required-building {
    display: flex;
    grid-gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 40px;

    &-item {
      width: 260px;

      display: flex;
      flex-direction: column;

      border: 0.5px solid #A0AEC0;
    }
    
    & .image-card {
      width: 100%;
      height: 107px;
      
      background-color: #A0AEC0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    & .bottom {
      padding:  18px 20px 20px;
    }

    & .name {
      font-weight: 600;
      font-size: 20px;
      color: #2D3748;
      text-align: center;
      margin-bottom: 0px !important;
    }
  }

  & .send-btn {
    height: fit-content !important;
    padding: 12px 30px;
    border-radius: 10px;
    background-color: #128FFF !important;
    margin-bottom: 50px;

    & span {
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }

  & .checkbox-variant {
    width: fit-content;
    display: flex;
    grid-gap: 15px;

    & .label {
      font-weight: 400;
      font-size: 16px;
      color: #2D3748;
    }
  }

  & .form-btns {
    & button {
      height: auto;
      color: #fff;
      font-weight: 600;
      padding: 20px 30px;
      background: #128FFF;
      border-radius: 10px;
    }

    & .success-button {
      background: #18CEC9;
      border: none;
    }

    & .cancel-button {
      background: #CBD5E0;
    }
  }
}