body {
  overflow-x: hidden;
  &.no-scroll{
    overflow: hidden;
  }
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
}

.landing-title {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 32px;
  color: #080A1C;
  margin-bottom: 0;
}

.bread-crumbs {
  display: flex;
  grid-gap: 12px;
  margin-bottom: 12px;

  & a,
  & button,
  & span {
    display: inline-flex;
    align-items: center;
    grid-gap: 10px;

    font-size: 16px;
    line-height: 1;
    color: #718096;
    margin-bottom: 0;
    transition: all .2s ease-in-out;

    & svg {
      display: inline-block;
      height: 22px;

      fill: #718096;
      transform: rotate(180deg);
    }
  }

  & button {
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
  }

  & a:hover,
  & button:hover {
    color: #128FFF;

    & svg {
      fill: #128FFF;
    }
  }
}

main.home-page {
  background: #EDF2F7;
  & section {
    padding: 110px 0;
  }
}

.intro {
  padding: 100px 0 0 !important;
  margin: 0;
  background: url('../../../assets/images/tmp/landing/landing-intro-bg.jpg');
  background-size: cover;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    display: block;
    height: 100%;
    width: 33.5%;

    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
  }

  &-inner {
    max-height: 610px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 140px 0 180px;

    position: relative;
    z-index: 2;
  }


  &-title {
    font-weight: 800;
    font-size: 60px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.3;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 50px;
  }

  &-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;

    letter-spacing: 5px;
    text-transform: uppercase;

    color: #FFFFFF;
  }

  &-submit-btn {
    height: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    color: #FFFFFF !important;

    padding: 20px 30px 18px;
    border: 2px solid #FFFFFF !important;
    background-color: transparent !important;
    border-radius: 10px;

    &:hover,
    &:focus {
      background-color: rgba(#fff, .2) !important;
    }
  }

  &-socialNetworks {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    margin-bottom: 50px;

    &-link {
      height: 38px;
      width: 38px;
    
      display: flex;
      align-items: center;
      justify-content: center;
    
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(5px);
      border-radius: 5px;
      transition: all .2s ease-in-out;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }

      & svg {
        fill: white;
      }
    }
  }
}

.map-landing {
  padding-bottom: 0 !important;
}

.projects-landing {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  &-control {
    display: flex;
    grid-gap: 25px;

    & button {
      height: 24px;
      cursor: pointer;

      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      margin: 0;

      &:hover svg,
      &:focus svg {
        fill: #128FFF;
      }
    }

    & button:first-child {
      transform: rotate(180deg);
    }

  }
  &-slider {
    display: flex;
    overflow-x: auto;
    grid-gap: 30px;
    // scroll-behavior: smooth;

    cursor: grabbing;

    width: 3000px;
    margin: 0px -1000px;
    padding: 10px 1000px;
    transition: all .3s ease-in-out;

    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .scrollSmooth {
    scroll-behavior: smooth;
  }

  &-item {
    height: 290px;
    width: 515px;
    min-width: 515px;

    border-radius: 8px;
    position: relative;
    overflow: hidden;

    user-select: none;
    
    &-bg {
      height: 100%;
      width: 100%;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      background-size: cover;
      transition: all .2s linear;
    }

    &::after {
      content: '';

      display: block;
      height: 100%;
      width: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      opacity: .3;

      background-color: rgba(8, 10, 28, 1);
      transition: all .3s linear;
    }

    &-title {
      width: 100%;
      min-height: 60px;
      display: flex;
      align-items: center;
      padding: 30px 35px;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 3;

      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;
      text-transform: uppercase;
      color: #FFFFFF;

      background: rgba(8, 10, 28, 0.1);
      backdrop-filter: blur(2.5px);
      border-radius: 10px;
      margin-bottom: 0;
    }

    &:hover {
      & .projects-landing-item-bg {
        height: 110%;
        width: 110%;
      }
      &::after {
        opacity: .5;
      }
    }
  }
}

.becomePartner {
  padding: 0 !important;
  padding-left: 40% !important;
  background: #999 url('../../../assets/images/tmp/landing/become-partner-bg.jpg') center no-repeat;
  background-size: cover;

  &-inner {
    padding: 110px;
    padding-right: 100px;
    background: rgba(8, 10, 28, 0.5);
    backdrop-filter: blur(5px);
    & .landing-title {
      color: #FFFFFF;
      margin-bottom: 15px;
    }
  }

  &-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    color: #F2F2F2;
    margin-bottom: 50px;
  }

  &-submit {
    &-link {
      display: block;
      margin-bottom: 50px;
    }
    &-btn {
      height: auto !important;

      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      text-transform: uppercase;
      color: #FFFFFF;

      padding: 20px 30px 18px !important;
      border-radius: 10px;
      background: #128FFF;
    }
  }

  &-contacts {
    &-title {
      font-weight: 600;
      font-size: 20px;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-bottom: 24px;
    }
    &-link-block {
      display: flex;
      align-items: center;
      grid-gap: 10px;
  
      margin-bottom: 15px;
  
      & p {
        margin-bottom: 0;
      }
      &:hover {
        & p::after {
          width: 100%;
        }
      }
    }
  
    &-link-block p,
    &-link {
      width: fit-content;
      display: inline-block;
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      color: #fff;
      white-space: nowrap;
      position: relative;
      transition: color .3s ease-in-out;
  
      &::after {
        content: '';
  
        display: block;
        width: 0;
        height: 2px;
  
        position: absolute;
        left: 0;
        top: calc(100% + 4px);
  
        background-color: #fff;
        transition: width .3s ease-in-out;
      }
    }
  }
}

.partners-landing {
  background-color: white;

  & .landing-title {
    margin-bottom: 50px;
  }

  &-items {
    width: 100%;
    max-width: 1120px;
    margin-bottom: 35px;

    &-title {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 1.2;
      text-transform: uppercase;
      color: #080A1C;
      margin-bottom: 20px;
    }

    & .partners-landing-item:last-child {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
      border: none !important;
    }
  }

  &-item {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-gap: 40px;
    padding-left: 40px;

    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #E9EBED;

    &-preview {
      width: 250px;
      height: 120px;
      
      display: flex;
      align-items: center;
      justify-content: center;

      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      &.gerb { background-image: url('../../../assets/images/tmp/partners/gerb.jpg'); }
      &.kazakh-tourism { background-image: url('../../../assets/images/tmp/partners/kazakh-tourism.jpg'); }
      &.damu-kz { background-image: url('../../../assets/images/tmp/partners/damu.jpg'); }
      &.kzppp-kz { background-image: url('../../../assets/images/tmp/partners/kzppp-kz.jpg'); }
      &.kazakh-invest { background-image: url('../../../assets/images/tmp/partners/kazakh-invest.jpg'); }
      &.kdb-kz { background-image: url('../../../assets/images/tmp/partners/kdb-kz.jpg'); }
    }

    &-title,
    &-text,
    &-text a {
      font-size: 20px;
      line-height: 1.4;
      color: #000000;
      margin-bottom: 0;
    }

    &-title {
      font-weight: 600;
    }

    &-text a {
      position: relative;
      transition: color .3s ease-in-out;

      &::after {
        content: '';

        display: block;
        width: 0;
        height: 2px;

        position: absolute;
        left: 0;
        top: calc(100% + 4px);

        background-color: #128FFF;
        transition: width .3s ease-in-out;
      }

      &:hover {
        color: #128FFF;
        &::after {
          width: 100%;
        }
      }
    }
  }
}

.becomePartner-submit-btn {
  margin-top: 50px;
}

.callBackModal {
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  h2 {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 100px;

    width: 100%;

    /* identical to box height, or 312% */
    text-transform: uppercase;

    text-align: center;

    color: #272727;

    margin-top: 62px;
  }

  &__form {
    width: 454px;

    display: flex;
    flex-direction: column;

    align-items: flex-start;

    .ant-form-item {
      width: 100%;

      .ant-input {
        background: rgba(237, 242, 247, 0.8);
        border-radius: 10px;
        height: 50px;

        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        /* identical to box height */

        color: black;
      }

      .ant-input::placeholder {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        /* identical to box height */

        color: #CBD5E0;
      }
    }

    &__phone {
      margin-bottom: 50px;
    }

    &__button {
      align-self: center;
      width: 220px !important;
      height: 56px !important;

      background: #128FFF;
      border-radius: 10px;

      .ant-btn {
        width: 220px !important;
        height: 56px !important;
  
        background: #128FFF;
        border-radius: 10px;
      }

      .ant-btn:hover {
        color: #fff;
        border-color: #40a9ff;
        background: #40a9ff;
      }
    }
  }
}