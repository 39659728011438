.sending-application {
  & .totalsBlock {
    overflow: hidden;
    border: 0.5px solid #A0AEC0;
    margin-bottom: 50px;

    & .ant-table-thead {
      height: 60px;
      position: relative;

      & .ant-table-cell {
        font-weight: 600;
        font-size: 20px;
        color: #FFFFFF;
        margin-bottom: 0px !important;
      }

      &::before {
        content: '';

        display: block;
        height: 80px;
        width: 140%;

        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        background: #96A0B5;
      }
    }

    &-body {
      padding: 0 20px;

      & .title-block .title {
        margin-bottom: 2px;
      }

      & .title-deadlines {
        font-weight: 400;
        font-size: 16px;
        color: #96A0B5;
        margin-bottom: 0px !important;

        & span {
          color: #2D3748;
        }
      }
    }
  }
}