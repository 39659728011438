.investmentMap {
  &.active {
    & #chartMapdiv {
      width: calc(100% - 200px);
    }
    & .oblProjects {
      right: 0;
    }
  }
  & #chartMapdiv {
    width: 100%;
    height: 80vh;
    min-height: 500px;
    transition: width .5s ease-in-out;
  }
  & .oblProjects {
    width: 475px;
    height: 100%;
    overflow-y: auto;

    position: absolute;
    top: 0;
    bottom: -18px;
    right: -500px;
    z-index: 10;

    padding: 0 !important;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(160, 174, 192, 0.25);
    transition: right .5s ease-in-out;

    & .close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 25px;
      z-index: 2;
      opacity: .8;
      transition: all .2s ease-in-out;

      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;

      &:hover {
        opacity: 1;
      }
    }

    &-obl {
      display: grid;
      grid-template-columns: 52px 1fr;
      grid-gap: 20px;
      background: #FFF1CB;

      padding: 27px 20px;

      & .pin {
        height: 52px;
        width: 52px;
        
        display: flex;
        align-items: center;
        justify-content: center;
        
        border-radius: 50%;
        background: #FFBE0B;

        & svg {
          fill: #FFF1CB;
        }
      }

      & .name {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
        color: rgba(5, 26, 45, 0.8);
        margin-bottom: 5px;
      }
      & .count {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.2;
        color: #051A2D;
        margin-bottom: 12px;
      }
      & .price {
        display: inline;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.2;
        color: #051A2D;

        padding: 8px 15px;

        background: #FFD050;
        border-radius: 10px;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      padding: 27px 20px;
      overflow-y: auto;

      & .project {
        display: grid;
        grid-template-columns: 12px 1fr;
        grid-gap: 12px;
        position: relative;
        overflow: hidden;
        
        padding: 12px;
        border: 2px solid #E9EBED;
        border-radius: 10px;
        transition: all .3s linear;

        &:hover {
          border-color: rgba(#051A2D, .8);
        }

        & .pdf_svg {
          width: 35px;
          height: 44px;

          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }

      & .pin {
        height: 12px;
        width: 12px;
        background: #FFFFFF;
        border: 3px solid #FFD050;
        border-radius: 50%;
        margin-top: 4px;
      }

      & .name {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.1;
        color: #051A2D;
        margin-bottom: 10px;
      }

      & .type {
        font-weight: 400;
        font-size: 13px;
        line-height: 1.2;
        text-transform: uppercase;
        color: #9A9EA6;
        margin-bottom: 10px;

        & span {
          color: #051A2D;
          text-transform: capitalize;
        }
      }

      & .price {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2;
        color: #051A2D;
      }
    }
  }
}